import React from "react";
import HomeButton from "../Components/HomeButton";
import "./Main.css"
import emptyImage from "../Assets/empty.gif"

const Work = () => {
    return (
        <div className="subscreen-container">
            <HomeButton />
            <h1>Work</h1>
            <p>Coming soon</p>
            <img style={{ width: 300, height: 225 }} src={emptyImage} alt="Coming Soon"></img>

            {/* <p>Oct 2023 - Now</p>
            <h2>Santa's AI Lab Inc.</h2>
            <h3>CTO</h3>
            <p></p>
            <h3>Flock Newsletter</h3>
            <p></p>
            <div className="links-list">
            </div>
            <div className="image-gallery"> */}
            {/* <div className="image-gallery-item">
                    <img src={BrunoChars} alt="Early Character Designs"></img>
                    <p>Early Character Designs</p>
                </div>
                <div className="image-gallery-item">
                    <img src={BrunoStoryboard} alt="Early Storyboards"></img>
                    <p>Early Storyboards</p>
                </div>
                <div className="image-gallery-item">
                    <img src={BrunoProgress} alt="Choosing the cover design"></img>
                    <p>Choosing the cover design</p>
                </div>
                <div className="image-gallery-item">
                    <img src={BrunoCover} alt="Final Design for English version"></img>
                    <p>Final Design for English version</p>
                </div> */}
            {/* </div>

            <h3>Mixmatch</h3>
            <p></p>
            <div className="links-list">
                <a href="https://gomixmatch.com">https://gomixmatch.com</a>
                <a href="https://mymixmatch.com/">https://mymixmatch.com/</a>
            </div>
            <div className="image-gallery">
            </div>

            <h3>Blue Jay and Iceberg</h3>
            <p></p>
            <div className="links-list">
            </div>
            <div className="image-gallery">
            </div>

            <br />
            <p>Jan - Oct 2023</p>
            <h2>Music Makes Us Feels Inc.</h2>
            <h3>Senior Engineer</h3>
            <p></p>
            <h3>Feels</h3>
            <p></p>
            <div className="links-list">
            </div>
            <div className="image-gallery">
            </div>

            <br />
            <p>Sep - Dec 2022</p>
            <h2>Lyfe Inc.</h2>
            <h3>Interim CTO</h3>
            <p></p>
            <h3>Lyfe App</h3>
            <p></p>
            <div className="links-list">
            </div>
            <div className="image-gallery">
            </div>

            <br />
            <p>Apr 2020 - Sep 2022</p>
            <h2>Snap Inc.</h2>
            <h3>Design Engineer</h3>
            <p></p>
            <h3>Snap App</h3>
            <p></p>
            <div className="links-list">
            </div>
            <div className="image-gallery">
            </div>

            <br />
            <p>Dec 2017 - Apr 2020</p>
            <h2>RBC Inc.</h2>
            <h3>Senior iOS Engineer</h3>
            <p></p>
            <h3>Ava</h3>
            <p></p>
            <div className="links-list">
            </div>
            <div className="image-gallery">
            </div>

            <h3>Client Mobile</h3>
            <p></p>
            <div className="links-list">
            </div>
            <div className="image-gallery">
            </div>

            <br />
            <p>Dec 2015 - Apr 2020</p>
            <h2>StoryStar Inc.</h2>
            <h3>Head of Mobile Engineering</h3>
            <p></p>
            <h3>Many projects</h3>
            <p></p>
            <div className="links-list">
            </div>
            <div className="image-gallery">
            </div> */}

        </div>
    );
};

export default Work;




// const projects = [
//     {
//         title: "Bruno the Fox and the Butterfly",
//         description: "A children's book about Bruno the Fox's adventure with a butterfly.",
//         images: [
//             { src: "https://picsum.photos/213/276?random=1", alt: "Early Character Designs" },
//             { src: "https://picsum.photos/213/276?random=2", alt: "Early Storyboards" },
//             { src: "https://picsum.photos/213/276?random=3", alt: "Choosing the cover design" },
//             { src: "https://picsum.photos/213/276?random=4", alt: "Final Design for English version" },
//             { src: "https://picsum.photos/213/276?random=5", alt: "Additional Scene 1" },
//             { src: "https://picsum.photos/213/276?random=6", alt: "Additional Scene 2" },
//             { src: "https://picsum.photos/213/276?random=7", alt: "Additional Scene 3" },
//             { src: "https://picsum.photos/213/276?random=8", alt: "Additional Scene 4" }
//         ],
//         links: [
//             { text: "(🇬🇧) Bruno the Fox and the Butterfly", url: "https://a.co/d/jgxfAGg" },
//             { text: "(🇺🇦) Лисеня Бруно і Метелик", url: "https://a.co/d/jgxfAGg" }
//         ]
//     },
//     {
//         title: "Bruno the Fox and the Music",
//         description: "A sequel to Bruno's adventures, exploring the world of music.",
//         images: [
//             { src: "https://picsum.photos/213/276?random=9", alt: "Bruno and Music Scene 1" },
//             { src: "https://picsum.photos/213/276?random=10", alt: "Bruno and Music Scene 2" },
//             { src: "https://picsum.photos/213/276?random=11", alt: "Bruno and Music Scene 3" },
//             { src: "https://picsum.photos/213/276?random=12", alt: "Bruno and Music Scene 4" },
//             { src: "https://picsum.photos/213/276?random=13", alt: "Bruno and Music Scene 5" },
//             { src: "https://picsum.photos/213/276?random=14", alt: "Bruno and Music Scene 6" },
//             { src: "https://picsum.photos/213/276?random=15", alt: "Bruno and Music Scene 7" },
//             { src: "https://picsum.photos/213/276?random=16", alt: "Bruno and Music Scene 8" }
//         ]
//     },
//     {
//         title: "Ben the Turtle and a gift for a teacher",
//         description: "A heartwarming story about Ben the Turtle's quest to find the perfect gift.",
//         images: [
//             { src: "https://picsum.photos/213/276?random=17", alt: "Ben the Turtle Character" },
//             { src: "https://picsum.photos/213/276?random=18", alt: "Ben's Gift Scene 1" },
//             { src: "https://picsum.photos/213/276?random=19", alt: "Ben's Gift Scene 2" },
//             { src: "https://picsum.photos/213/276?random=20", alt: "Ben's Gift Scene 3" },
//             { src: "https://picsum.photos/213/276?random=21", alt: "Ben's Gift Scene 4" },
//             { src: "https://picsum.photos/213/276?random=22", alt: "Ben's Gift Scene 5" },
//             { src: "https://picsum.photos/213/276?random=23", alt: "Ben's Gift Scene 6" },
//             { src: "https://picsum.photos/213/276?random=24", alt: "Ben's Gift Scene 7" }
//         ]
//     },
//     {
//         title: "IT Project 1",
//         description: "An innovative IT solution for modern businesses.",
//         images: [
//             { src: "https://picsum.photos/213/276?random=25", alt: "IT Project 1 Screenshot 1" },
//             { src: "https://picsum.photos/213/276?random=26", alt: "IT Project 1 Screenshot 2" },
//             { src: "https://picsum.photos/213/276?random=27", alt: "IT Project 1 Screenshot 3" },
//             { src: "https://picsum.photos/213/276?random=28", alt: "IT Project 1 Screenshot 4" },
//             { src: "https://picsum.photos/213/276?random=29", alt: "IT Project 1 Screenshot 5" },
//             { src: "https://picsum.photos/213/276?random=30", alt: "IT Project 1 Screenshot 6" },
//             { src: "https://picsum.photos/213/276?random=31", alt: "IT Project 1 Screenshot 7" },
//             { src: "https://picsum.photos/213/276?random=32", alt: "IT Project 1 Screenshot 8" }
//         ]
//     },
//     {
//         title: "Sports and Fitness",
//         description: "A collection of my sports and fitness activities.",
//         images: [
//             { src: "https://picsum.photos/213/276?random=33", alt: "Playing Tennis" },
//             { src: "https://picsum.photos/213/276?random=34", alt: "Volleyball Match" },
//             { src: "https://picsum.photos/213/276?random=35", alt: "Pickleball Game" },
//             { src: "https://picsum.photos/213/276?random=36", alt: "Yoga Session" },
//             { src: "https://picsum.photos/213/276?random=37", alt: "Gym Workout" },
//             { src: "https://picsum.photos/213/276?random=38", alt: "Swimming" },
//             { src: "https://picsum.photos/213/276?random=39", alt: "Running" },
//             { src: "https://picsum.photos/213/276?random=40", alt: "Cycling" }
//         ]
//     },
//     {
//         title: "IT Project 2",
//         description: "A cutting-edge IT solution for data management and analysis.",
//         images: [
//             { src: "https://picsum.photos/213/276?random=41", alt: "IT Project 2 Screenshot 1" },
//             { src: "https://picsum.photos/213/276?random=42", alt: "IT Project 2 Screenshot 2" },
//             { src: "https://picsum.photos/213/276?random=43", alt: "IT Project 2 Screenshot 3" },
//             { src: "https://picsum.photos/213/276?random=44", alt: "IT Project 2 Screenshot 4" },
//             { src: "https://picsum.photos/213/276?random=45", alt: "IT Project 2 Screenshot 5" },
//             { src: "https://picsum.photos/213/276?random=46", alt: "IT Project 2 Screenshot 6" },
//             { src: "https://picsum.photos/213/276?random=47", alt: "IT Project 2 Screenshot 7" },
//             { src: "https://picsum.photos/213/276?random=48", alt: "IT Project 2 Screenshot 8" }
//         ]
//     }
// ];