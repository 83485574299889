import React from "react";
import './Main.css';
import BrunoProgress from "../Assets/Bruno_the_fox_buterfly_assets/Bruno_Progress.jpg"
import BrunoCover from "../Assets/Bruno_the_fox_buterfly_assets/Bruno_eBook_cover_English.jpeg"
import BrunoChars from "../Assets/Bruno_the_fox_buterfly_assets/Bruno_chars.jpeg"
import BrunoStoryboard from "../Assets/Bruno_the_fox_buterfly_assets/Bruno_storyboards.jpeg"
import HomeButton from "../Components/HomeButton";

const Hobbies = () => {

    return (
        <div className="subscreen-container">
            <HomeButton />
            <h1>Hobbies</h1>
            <h2>Sport</h2>
            <p>I am very active playing tennis, volleyball, and pickleball, as well going to the gym, yoga, and swimming. My overall attitude to health is holistic. I like to pay attention to food, sleep, breath, and exercise.</p>
            <br></br>
            <h2>Children Books</h2>
            <p>I write children books. So far I have written 3 books, and fully published one of them. I am always happy to colaborate with parents, education and children professionals, illustrators, editors, and publishers. <a href="/contacts">Contact me</a></p>
            <h3>Bruno the Fox and the Butterfly</h3>
            <div className="links-list">
                <a href="https://a.co/d/jgxfAGg">(🇬🇧) Bruno the Fox and the Butterfly</a>
                <a href="https://a.co/d/7GvYbWl">(🇺🇦) Лисеня Бруно і Метелик</a>
            </div>
            <div className="image-gallery">
                <div className="image-gallery-item">
                    <img src={BrunoChars} alt="Early Character Designs"></img>
                    <p>Early Character Designs</p>
                </div>
                <div className="image-gallery-item">
                    <img src={BrunoStoryboard} alt="Early Storyboards"></img>
                    <p>Early Storyboards</p>
                </div>
                <div className="image-gallery-item">
                    <img src={BrunoProgress} alt="Choosing the cover design"></img>
                    <p>Choosing the cover design</p>
                </div>
                <div className="image-gallery-item">
                    <img src={BrunoCover} alt="Final Design for English version"></img>
                    <p>Final Design for English version</p>
                </div>
            </div>
            {/* <h3>Bruno the Fox and the Music</h3>
            <h3>Ben the Turtle and a gift for a teacher</h3>
            <p></p>
            <h2>Family Tree</h2>
            <p></p> */}
        </div >
    )
}

export default Hobbies