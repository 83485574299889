import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomeButton.css';
import HomeIcon from '../Assets/home_icon.png';

function HomeButton() {
    const navigate = useNavigate();

    return (
        <button className="home-button" onClick={() => navigate('/')}>
            <img src={HomeIcon} alt="Home" />
        </button>
    );
}

export default HomeButton;
