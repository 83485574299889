import React from 'react';
import './Main.css';
import HomeButton from '../Components/HomeButton';

function Education() {
    return (
        <div className="subscreen-container">
            <HomeButton />
            <h1>Education</h1>
            <p>My journey in tech, sports, and finance spans multiple countries, blending mathematics, computer science, and continuous learning.</p>

            <h2>Key Educational Milestones</h2>
            <ul>
                <li><strong>2011-2015:</strong> Bachelor's in Applied Mathematics and Computer Science<br />
                    National University of Kyiv-Mohyla Academy (NaUKMA), Ukraine
                </li>
                <li><strong>2015-2017:</strong> Master's in Computer Science<br />
                    Brock University, Canada
                </li>
                <li><strong>2018-2019:</strong> Certificate in Financial Trading, Investments, and Options<br />
                    University of Toronto, Canada
                </li>
            </ul>

            <h2>Core Skills</h2>
            <ul>
                <li>Programming: C++, Java, JavaScript, Objective-C, iOS Development</li>
                <li>Mathematics & Computer Science</li>
                <li>Parallel Computing & GPU Programming</li>
                <li>Product Design & User Experience</li>
                <li>Financial Trading Strategies</li>
            </ul>

            <h2>Highlights</h2>
            <ul>
                <li>Developed university library app and contributed to UK fashion startup</li>
                <li>Master's Thesis: Non-Photorealistic Rendering using GPUs</li>
                <li>Continuous learner: Self-taught iOS development, exploring new tech</li>
                <li>Focus on consumer-oriented products and user-centric solutions</li>
            </ul>

            <p>My education fostered problem-solving skills and adaptability, setting a strong foundation for my tech career.</p>
        </div>
    );
}

export default Education;