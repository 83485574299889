import React from "react";
import './Main.css';
import HomeButton from "../Components/HomeButton";
import emptyImage from "../Assets/empty.gif"

const Mission = () => {

    return (
        <div className="subscreen-container">
            <HomeButton />
            <h1>Mission</h1>
            <p>Coming soon</p>
            <img style={{ width: 300, height: 225 }} src={emptyImage} alt="Coming Soon"></img>
        </div>
    )
}

export default Mission